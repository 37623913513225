@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap'); */
.hrBgColor {
  color: #e4e4e4;
  height: 1px;
}
/* xyz */
.linkStyle {
  text-decoration: none;
  color: black;
  position: relative;
  padding-bottom: 4px;
}
.linkStyling {
  text-decoration: none;
  color: black;
  position: relative;
  padding-bottom: 4px;
}
p.seti {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.12px;
  line-height: 40px;
  margin-top: 25px;
  margin-bottom: 1rem;
}

.modalButton {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 30px;
  letter-spacing: 1.4px;
  line-height: normal;
  text-transform: uppercase;
  width: 100px;
  background-color: #000;
  color: #fff;
}
.modalContent {
  color: #000;
  font-family: Arial-400;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1.12px;
  line-height: normal;
  text-align: center;
}
.carousel .thumb img {
  max-height: 100px !important;
  width: auto !important;
}
.zoomInEffect {
  transition: all 0.2s ease;
  mix-blend-mode: multiply;
}
.zoomInEffect:hover {
  transform: scale(1.1);
}
.onHoverCursor {
  cursor: pointer;
}
.sticky-top {
  z-index: 99 !important;
}
.owl-nav {
  margin-top: 2rem !important;
  display: flex !important;
  justify-content: end !important;
}

a {
  text-decoration: none !important;
}
@font-face {
  font-family: "Montserrat-400";
  src: url("../public/assets/css/fonts/Montserrat-Regular.ttf")
    format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat-500";
  src: url("../public/assets/css/fonts/Montserrat-Medium.ttf")
    format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat-600";
  src: url("../public/assets/css/fonts/Montserrat-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat-700";
  src: url("../public/assets/css/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Arial-400";
  src: url("../public/assets/css/fonts/ARIAL.TTF") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Arial-500";
  src: url("../public/assets/css/fonts/ArialMdm.ttf") format("truetype");
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(236, 235, 235);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(131, 130, 130);
  border-radius: 10px;
}

._success {
  box-shadow: 0 15px 25px #00000019;
  padding: 45px;
  width: 100%;
  text-align: center;
  margin: 40px auto;
  border-bottom: solid 4px #000;
}

._success h2 {
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

._success p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #495057;
  font-weight: 500;
}
/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
  color:red;
} */

.linkStyle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1.5px;
  border-radius: 4px;
  background-color: #18272f;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.linkStyle:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}
.accordion-button:not(.collapsed) {
  color: black;
}

.container {
  max-width: 90% !important;
  margin: auto !important;
}

/* Search bar of nav start */

input#searchright {
  border-bottom: 1px solid;
}

.button:hover {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: white;
  color: black;
}

.search-container {
  position: relative;
  display: inline-block;
  margin: 4px 2px;
  height: 30px;
  width: 50px;
  top: -4px;
  /* vertical-align: bottom; */
}

.mglass {
  display: inline-block;
  pointer-events: none;
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

.mglass svg {
  font-size: 18px;
  font-weight: 200;
}

.searchbutton {
  position: absolute;
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.search:focus + .searchbutton {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: white;
  color: black;
}

.search {
  position: absolute;
  left: 49px;
  outline: none;
  border: none;
  padding: 0;
  width: 0;
  height: 83%;
  z-index: 10;
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
}

.search-container input.search:focus {
  width: 150px; /* Bar width+1px */
  padding: 0 16px 0 0;
}

.search-container .expandright {
  left: auto;
  right: 30px;
  background-color: #fff;
}

.search-container input.expandright:focus {
  width: 150px; /* Bar width+1px */
  padding: 0 16px 0 0;
  padding: 0 0 0 8px;
}
.NavNeedHelp .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  top: 6px;
  right: -14px;
  border-top: unset;
  border-left: unset;
}
input#searchright {
  border-bottom: 1px solid;
}
.modalButton {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
  /* border-radius: 8px; */
  background: #000;
  height: 34px;
  padding: 0px 8px;
  border: none;
  margin-top: -3px;
  display: grid;
  place-content: center;
}
.button {
  display: inline-block;
  margin: 4px 2px;
  /* background-color: #444; */
  font-size: 14px;
  padding-left: 32px;
  padding-right: 32px;
  height: 30px;
  line-height: 50px;
  text-align: center;
  color: rgb(0, 0, 0);
  text-decoration: none;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button:hover {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: white;
  color: black;
}

#needHelpDatePickerHolder .react-datepicker-wrapper {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 5px;
}
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(10px, 0);
  }
  66% {
    transform: translate(-10px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.search-container {
  position: relative;
  display: inline-block;
  margin: 4px 2px;
  height: 11px;
  width: 50px;
  top: -12px;
  left: 46px;
}

.mglass {
  display: inline-block;
  pointer-events: none;
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

.mglass svg {
  font-size: 18px;
  font-weight: 200;
}

.searchbutton {
  position: absolute;
  font-size: 22px;
  margin: 0;
  padding: 0;
}

.search:focus + .searchbutton {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: white;
  color: black;
}
.search::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 10px 10px;
}
.abc {
  display: inline;
  color: black;
}
.search {
  position: absolute;
  left: 49px;
  outline: none;
  border: none;
  padding: 0;
  width: 0;
  height: 83%;
  z-index: 10;
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
}

.search-container input.search:focus {
  width: 150px; /* Bar width+1px */
  padding: 0 16px 0 0;
}

.search-container .expandright {
  bottom: -21px;
  left: auto;
  right: 30px;
  background-color: #fff;
  height: 29px;
}

.search-container input.expandright:focus {
  width: 190px;
  padding: 0 16px 0 0;
  padding: 0 0 0 8px;
}
.NavNeedHelp .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  top: 6px;
  right: -14px;
  border-top: unset;
  border-left: unset;
}

.searchCode {
  font-size: 12px;
  left: -62px;
  position: absolute;
}

.accordion-collapse.collapse.show {
  padding: 8px 0;
}
.react-datepicker__input-container button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 7px;
  background-color: black;
  color: white;
  outline: none;
  font-family: "Montserrat-500";
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 266.667% */
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.css-1xc3v61-indicatorContainer {
  color: #fff !important;
}
