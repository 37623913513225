.reasonContainer {
}
.iconHolder {
  height: 40px;
  width: auto;
}
.flexBox {
  display: flex;
}
.reasonCard {
  height: 60px;
  border: 0.5px solid #d5d9d9;
  /* align-items: center; */
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 18px;
  position: relative;
  cursor: pointer;
  height: auto;
}
.imgHolder {
  display: flex;
  justify-content: center !important; /* Horizontally center the images */     /* Vertically center the images */
  flex-wrap: wrap;         /* Allow images to wrap if there's too much space */
  width: 100%; 
  justify-content: center !important;   
  margin-top: 10px !important;
           /* Ensure the parent takes up the full width */
}


.fileIcon {
  width: 100px;           
  height: 100px;         
  display: flex;
  justify-content: center; 
  align-items: center;    

}

.imagePreview {
  max-width: 100%;        /* Ensure the image scales within its container */
  max-height: 100%;       /* Ensure the image scales within its container */
  /* object-fit: contain;    Maintain aspect ratio */
}

/* Ensure that images inside the .imgHolder take full space and maintain their aspect ratio */
.fileIcon img {
  width: 100%;            /* Let image take the full width of the parent */
  height: 100%;           /* Let image take the full height of the parent */
  object-fit: cover;      /* Make sure the image covers the entire div */
  object-position: center; /* Keep the image centered */
  border-radius: 8px;     /* Optional: Add rounded corners */
  max-width: 100%;        /* Ensure the image doesn't overflow horizontally */
  max-height: 100px;  
  min-height: 100px;  
  justify-content: center; /* Centers icons horizontally */
  align-items: center;     /* Optional: Set a max-height for images */
}

/* Optional: Styling for the count text */
.countText {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
  font-family: "Montserrat" !important;
}




.reasonHolder {
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 20px;
}
.activeReason {
  border: 1px dashed #3c74b4;
  position: relative;
  color: #3c74b4;
}
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(5px, 0);
  }
  40% {
    transform: translate(0, 5px);
  }
  60% {
    transform: translate(-5px, 0);
  }
  80% {
    transform: translate(0, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.activeReason::after {
  content: "";
  width: 14px;
  height: 8px;
  border-bottom: solid 2px #3c74b4;
  border-left: solid 2px #3c74b4;
  transform: rotate(-45deg);
  position: absolute;
  right: 3%;
  top: 10%;
}
.reasonTitle {
  color: #000;
  text-align: left;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 2.2px;
  text-transform: uppercase;
  margin-top: 25px;
}
.textHolder {
  margin-left: 5px;
  margin-bottom: 0;
  line-height: 18px;
}
.descHolder {
  margin: 5px 5px 0 0;
  font-size: 11px;
}
@media (max-width: 1440.98px) {
  .reasonTitle {
    font-size: 17px;
    font-weight: 600;
  }
  .reasonHolder {
    grid-template-columns: repeat(3, auto);
  }
}

@media (max-width: 1024.98px) {

  .reasonTitle {
    font-size: 14px;
    font-weight: 600;
  }
}
