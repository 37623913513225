.scrollP {
  border: 1px solid #e4e4e4;
  padding: 10px 20px;
}
.Mainbox4:hover {
  cursor: pointer;
}
.MyBagFinalMain {
  border: 1px dashed #000;
  background: #fff;
  padding: 38px 30px 30px 10px;
  margin: 10px 0 0 0;
}
.PaymentType {
  margin-top: 20px;
  border: 1px solid rgba(0, 0, 0, 0.089);
  padding: 15px;
}
.PaymentType span {
  font-weight: 470;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.PaymentTypeHolder{
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 25px;
}
.ClearBag {
  background-color: #f1f0f0;
}
.MainInner {
  background: #fff;
  padding: 17px 27px;
  min-height: 350px;
  max-height: 50vh;
  overflow: auto;
}

.MainInner::-webkit-scrollbar {
  border-radius: 50px;
  background-color: #d9d9d9;
  width: 4px;
  margin: 0 10px 0 0;
  padding: 0 10px 0 0;
}

.MainInner::-webkit-scrollbar-thumb {
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #000000;
  width: 4px;
}

.MyBagFinalTop {
  display: flex;
  justify-content: space-between;
}

.MyBagFinalRight,
.MyBagFinalleft {
  display: flex;
  align-items: center;
  gap: 15px;
}

.MyBagFinalRight h4 {
  color: #000;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin: 0;
}

.MyBagFinalRight h4 span {
  font-size: 22px;
  letter-spacing: 2.2px;
}

.MyBagFinalleft h5 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.MyBagFinalleft h5 b {
  color: #000;
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.Mainbox {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  margin: 0 0 26px 0;
  padding: 5px;
  align-items: center;
}

.MainBag h3 {
  color: #000;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 177.778% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.Mainbox1M {
  display: flex;
  gap: 25px;
  align-items: center;
}

.Mainbox1M img {
  width: 100%;
  max-width: 100px;
}
.Mainbox3 {
  width: fit-content;
}
.Mainbox3 h2 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 228.571% */
  letter-spacing: 1.12px;
  margin: 0;
}

.Mainbox1M p {
  margin: 0 0 0 0;
  display: flex;
  gap: 10px;
}

.Span11 {
  color: #9c9c9c;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px; /* 266.667% */
  letter-spacing: 1.12px;
}

.Span1 {
  color: #9c9c9c;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 266.667% */
  letter-spacing: 1.12px;
  text-decoration: line-through;
}

.Span2 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 228.571% */
  letter-spacing: 1.12px;
}

.Mainbox2M {
  display: flex;
  align-items: center;
  /* gap: 20px; */
}

.Mainbox input {
  width: 42px;
  height: 26px;
  text-align: center;
  /* padding: 0; */
  line-height: 5;
  border-top: 0.5px solid black;
  border-bottom: 0.5px solid black;
}

.Mainbox button {
  height: 26px;
  border: 0.5px solid #000;
  background: #f8fafb;
  min-width: 22px;
  color: #000;
  font-size: 16px;
  /* text-align: ce; */
  font-weight: 400;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TotalPricer {
  display: flex;
  justify-content: space-between;
  padding: 10px 5px 0 5px;
  position: sticky;
  bottom: 5px;
  background: #fff;
  flex-direction: column;
}

.TotalPricer h2 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 177.778% */
  letter-spacing: 1.12px;
  margin: 0;
}
.Mainbox5 {
  margin: 0 0 0 20px;
}

.Mainbox2 img {
  width: 100%;
}

.ShipAdress {
  border: 1px solid #e4e4e4;
  background: #fff;
  padding: 15px;
}

.ShipAdress p {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px; /* 178.571% */
  letter-spacing: 1.12px;
  border-bottom: 1px dashed #000;
  padding: 0 0 8px 0;
}

.ShipAdress2 {
  background-color: #f8f8f8;
  padding: 20px 0 0px 20px;
  margin: 32px 0;
}

.ShippControl h2 {
  color: #000;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 177.778% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.ShipAdress2 h4 {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 177.778% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.ShipAdress2 textarea::placeholder,
.ShipAdress2 textarea {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 228.571% */
  letter-spacing: 1.12px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  /* text-transform: uppercase; */
}
.shipLabelHolder {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 228.571% */
  letter-spacing: 1.12px;
  text-transform: uppercase;
  margin: 0;
  margin-left: 5px;
}
.ShipAdress2 label {
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 228.571% */
  letter-spacing: 1.12px;
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  /* text-transform: uppercase; */
}
.warning {
  text-decoration: underline;
}
.templateHolder {
  width: 47%;
  min-width: 100px;
  position: relative;
  cursor: pointer;
  border: 2px solid transparent;
  padding: 10px;
  border-radius: 8px;
  transition: border-color 0.3s ease, transform 0.3s ease;
  background-color: #fff; /* Default background color */
  display: grid;
  place-content: center;
  border: 1px solid #ccc;
}

.templateHolder:hover {
  box-shadow: 0 0 10px rgba(13, 41, 71, 0.5);
  /* transform: scale(1.05); */
}
.templateHolder .labelHolder {
  margin: 0;
  text-align: start;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 0;
  padding: 0;
  font-weight: bold;
}
.selected {
  border-color: #000; /* Black border for selected templates */
  /* transform: scale(1.05); */
}
.ShipBut {
  width: 100%;
}
.ShipBut button {
  width: 100%;
}
.btnHolder {
  width: 100px;
}
.ShipBut button,
.btnHolder {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 213.333% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
  background-color: #000;
  margin: 0 0 28px 0;
  position: sticky;
  bottom: 50px;
}

.ClearBag {
  background-color: #f1f0f0;
  width: 100%;
  color: black;
  text-align: center;
  font-family: "Montserrat";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 213.333% */
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin: 0 0 28px 0;
}
@media (max-width: 1199px) {
  .MainInner {
    padding: 17px 5px;
  }
  .scrollP {
    padding: 10px 5px;
  }

  .Mainbox1M {
    gap: 15px;
  }
}

@media (max-width: 767.98px) {
  .MyBagFinalRight h4 span {
  }

  .MyBagFinalRight h4 {
    display: block;
  }

  .MyBagFinalTop {
    display: block;
    justify-content: space-between;
  }

  .MyBagFinalleft {
    margin: 23px 0 0 0;
  }
}

@media (max-width: 767.98px) {
  .Mainbox {
    display: block;
  }
  .Mainbox2M {
    margin: 20px 0 0px 0;
  }
}
