.listholder {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 20px 0;
    background-color: white;
  }
  
  .row {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .row:hover {
    background-color: #f5f5f5;
  }
  
  .row:last-child {
    border-bottom: none;
  }
  
  .info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 15px;
  }
  
  .poHolder {
    font-weight: bold;
    color: #333;
  }
  
  .orderText {
    color: #666;
  }
