.dropdown-toggle::after {
  content: none;
}
.text {
  text-align: center;
  font-family: Montserrat-500;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.language input{
  width: 58px;

}
.language input::placeholder{
  color: #000;
}


.language {
  color: #000;
  text-align: center;
  font-family: Arial-400;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 266.667% */
  letter-spacing: 1.12px;
  align-items: center;
}
.myRetailer {
  color: #fff;
  text-align: center;
  font-family: Arial-400;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 320% */
  letter-spacing: 1.12px;
  background-color: #000;
  text-transform: uppercase;
  text-decoration: none;
}
.mobileHeader {
  display: none !important;
}

.ControlMobNav{
  background-color: #000;
  display: flex;
  justify-content:space-around;
  align-items: center;

}

.MoblabelLine{
  width: 20px;
height: 20px;
background-color: #fff;
border-radius: 30px;
display: flex;
justify-content: center;
    align-items: center;
    margin: 0 0px 0 0;
}

.MoblabelLine svg{
  width:11px;
  height: 10px;

}
  .AfterRemove::after{
    content: unset !important;
  }

  .flexMain1{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .flexMain3{
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .flexMain2{
    display: flex;
    gap: 30px;
  }

  .myRetailerPaddingBorder{
    
    border-left: 1px solid #D9D9D9;
    border-right: 1px solid #D9D9D9;
    padding: 4px 21px 4px 21px;
  }
  .flexMain3 P{
    
  }
@media screen and (max-width: 767.98px) and (min-width: 200px) {
  .mobileHeader {
    display: block !important;
  }
  .text {
    text-align: center;
    font-family: Montserrat-500;
    font-size: 4px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
}
