.text {
  color: #fff;
  /* text-align: center; */
  text-align: left;
  font-family:Montserrat-500;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 266.667% */
  letter-spacing: 1.2px;
  text-transform: uppercase;
  border: none;
}
.breakHolder{
  width: 2px;
  height: 100%;
  background: #fff;
  margin: auto 0;
}
.inorderflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #000;
  padding: 0px 0px 20px 0px;
  margin: 27px 0 0 0;
}
.inorderflex h2 {
  color: #000;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2px;
  margin: 0;
}

.inorderflex button {
  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
  border-radius: 0px 8px 8px 0px;
  background: #000;
  height: 42px;
  padding: 0px 13px;
  border: none;
}
.InputControll {
  height: 42px;
  /* background-color: aqua; */
}

.InputControll select {
  height: 42px;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid #9c9c9c;
  background: #fff;
  padding: 0 15px;
  outline: none;
}

/* @media(max-width:767.98px){
  .FiltersInNewness{
  display: none !important;
}
} */

