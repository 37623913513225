.language {
  text-align: center;
  font-family: Arial-400;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 1.12px;
  /* text-transform: uppercase; */
}
.vr {
  border-right: 1px solid black;
  height: 12px;
}
.welcomeText {
  color: #9c9c9c;
  text-align: center;
  font-family: Arial-400;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 240% */
  letter-spacing: 1.12px;
}

.welcomeText span{
  color: #000;
text-align: center;
font-family: Arial-500;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 200% */
letter-spacing: 1.12px;
}
.nameText {
  text-align: center;
  font-family: Arial-500;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  letter-spacing: 1.12px;
}
.vrLarge {
  border-right: 1px solid black;
  height: 27px;
}
.topNav {
  display: flex;
}
.laptopMode {
 
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  /* width: 100%; */
 
}


.lapSetting{
  display: flex;
  gap: 30px;
  
}

.text a{
  color: #000;
text-align: center;
font-family: Montserrat-500;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 266.667% */
letter-spacing: 1.2px;
text-transform: uppercase;
text-decoration: none;
}

.laptopModeSticky{
  /* position: sticky; */
  /* top: 57px; */
  background-color: #fff;
  /* border: 1px solid; */
  z-index: 4;
}





 


@media screen and (max-width: 767.98px) and (min-width: 200px) {
  .laptopMode{
    display: none;
  }

  .NeedNone{
    display: none;
  }
}
@media screen and (max-width: 900px) and (min-width: 200px) {
  
  .lapSetting {
    gap: 12px;
}
}
